import type { Newable } from "ts-essentials";
import { deleteGlobals, freezeExistingGlobals } from "./clean-globals.ts";
import { transformCode } from "./transform-code.ts";
import type { DrawSample } from "../oscillioscope.ts";

// @types/audioworklet is wrong, so redeclare the broken types here
declare abstract class AudioWorkletProcessor {
	readonly port: MessagePort;
	constructor(options?: {
		numberOfInputs?: number;
		numberOfOutputs?: number;
		outputChannelCount: number[];
		parameterData?: Record<string, number>;
		processorOptions?: unknown;
	});
	abstract process(
		_inputs: Float32Array[][],
		outputs: Float32Array[][],
		_parameters: Record<string, Float32Array>,
	): boolean;
}
declare function registerProcessor(
	name: string,
	processorCtor: Newable<AudioWorkletProcessor>,
): void;

function safeStringify(value: unknown, quoteString?: boolean) {
	if (!quoteString && typeof value === "string") {
		return value;
	}
	return JSON.stringify(value);
}

function getErrorMessage(err: unknown, time: number) {
	if (
		err instanceof Error &&
		typeof err.lineNumber === "number" &&
		typeof err.columnNumber === "number"
	) {
		const message = safeStringify(err.message, false);

		if (time !== undefined) {
			return `${message} (at line ${err.lineNumber - 3}, character ${
				err.columnNumber
			}, t=${time})`;
		}
		return `${message} (at line ${err.lineNumber - 3}, character ${err.columnNumber})`;
	}
	if (time !== undefined) {
		return `Thrown: ${safeStringify(err, true)} (at t=${time})`;
	}
	return `Thrown: ${safeStringify(err, true)}`;
}

// replace Proxy so that they can be detected from the bytebeat code
// this is completely undetectable by the bytebeat code
const proxies = new WeakSet();
// biome-ignore lint/suspicious/noGlobalAssign: required monkeypatch
Proxy = Object.getPrototypeOf(Proxy).constructor = new Proxy(Proxy, {
	construct(target, args) {
		// @ts-expect-error
		const newProxy = new target(...args);
		proxies.add(newProxy);
		return newProxy;
	},
});

class BytebeatProcessor extends AudioWorkletProcessor {
	audioSample = 0;
	lastFlooredTime = -1;
	sample = 0;

	sampleRatio = NaN;

	lastByteValue = [null, null];
	lastValue = [0, 0];
	lastFuncValue = [null, null];

	func = null;
	calcByteValue = null;
	songData = { sampleRate: null, mode: null };
	sampleRateDivisor = 1;
	playSpeed = 0;

	sentError = null;
	constructor() {
		super({ numberOfInputs: 0, outputChannelCount: [2] });

		Object.seal(this);

		deleteGlobals();
		freezeExistingGlobals();

		this.updateSampleRatio();

		this.port.addEventListener("message", e => this.handleMessage(e));
		this.port.start();
	}

	handleMessage(e: MessageEvent<any>) {
		const data = e.data;

		// set vars
		for (const v of ["songData", "sampleRateDivisor", "playSpeed"]) {
			if (data[v] !== undefined) {
				this[v] = data[v];
			}
		}

		// run functions
		if (data.songData !== undefined) {
			this.updatePlaybackMode();
		}
		if (data.setByteSample !== undefined) {
			this.setByteSample(...data.setByteSample);
		}
		// other
		if (data.code !== undefined) {
			this.refreshCode(data.code); // code is already trimmed
		}
		if (data.updateSampleRatio) {
			this.updateSampleRatio();
		}
		if (data.recievedError && this.sentError === "runtime") {
			this.sentError = null;
		}
	}

	updatePlaybackMode() {
		this.calcByteValue = // create function based on mode
			this.songData.mode === "Bytebeat"
				? (funcValueC, c) => {
						this.lastByteValue[c] = funcValueC & 255;
						this.lastValue[c] = this.lastByteValue[c] / 127.5 - 1;
					}
				: this.songData.mode === "Signed Bytebeat"
					? (funcValueC, c) => {
							this.lastByteValue[c] = (funcValueC + 128) & 255;
							this.lastValue[c] = this.lastByteValue[c] / 127.5 - 1;
						}
					: this.songData.mode === "Floatbeat" || this.songData.mode === "Funcbeat"
						? (funcValueC, c) => {
								this.lastValue[c] = Math.min(Math.max(funcValueC, -1), 1);
								this.lastByteValue[c] = Math.round((this.lastValue[c] + 1) * 127.5);
							}
						: (funcValueC, c) => {
								this.lastByteValue[c] = NaN;
							};
	}
	setByteSample(value: number, clear = false) {
		this.sample = value;
		this.port.postMessage({ [clear ? "clearCanvas" : "clearDrawBuffer"]: true });
		this.audioSample = 0;
		this.lastFlooredTime = -1;
		for (let c = 0; c < 2; c++) {
			this.lastValue[c] = 0;
			this.lastByteValue[c] = null;
			this.lastFuncValue[c] = null;
		}
	}
	refreshCode(code: string) {
		// TODO: make arguments variable not defined
		// it can't be deleted, so maybe something involving a Proxy and getter?
		// TODO: make inner function arguments.callee.caller return null

		// code is already trimmed
		// create shortened Math functions and other things for compatibility
		const mathProps = Object.getOwnPropertyNames(Math);
		const params: string[] = [
			...mathProps,
			"int", "window", "arguments",
		] as const;
		const values: unknown[] = [
			...mathProps.map(k => Math[k as keyof Math]),
			Math.floor, globalThis, undefined,
		] as const;

		deleteGlobals();

		// test bytebeat
		const oldFunc = this.func;
		let errType;
		try {
			errType = "compile";
			if (this.songData.mode === "Funcbeat") {
				const transformedCode = transformCode(code, false);
				this.func = Function(...params, transformedCode).bind(globalThis, ...values);
			} else {
				const transformedCode = transformCode(code, true);
				this.func = Function(
					...params,
					"t",
					`return 0,\n${transformedCode || "undefined"}\n;`,
				).bind(globalThis, ...values);
			}
			errType = "firstrun";
			if (this.songData.mode === "Funcbeat") {
				this.func = this.func(); // TODO: dangerous?
			}
			// TODO: samplerate is undefined, not good for funcbeat
			// this also has side effects
			this.func(0);
		} catch (err) {
			// TODO: handle arbitrary thrown objects, and modified Errors
			if (errType === "compile") {
				this.func = oldFunc;
			}
			this.sentError = errType;
			this.port.postMessage({
				updateUrl: true,
				errorMessage: {
					err: getErrorMessage(err, 0),
					type: errType,
				},
			});
			return;
		}
		this.sentError = null;
		this.port.postMessage({ updateUrl: true, errorMessage: null });
	}
	updateSampleRatio() {
		const flooredTimeOffset = isNaN(this.sampleRatio)
			? 0
			: this.lastFlooredTime - Math.floor(this.sampleRatio * this.audioSample);
		// TODO: this is the only use of global sampleRate, can it be removed?
		this.sampleRatio = (this.songData.sampleRate * this.playSpeed) / sampleRate;
		this.lastFlooredTime = Math.floor(this.sampleRatio * this.audioSample) - flooredTimeOffset;
		return this.sampleRatio;
	}

	process(
		_inputs: Float32Array[][],
		outputs: Float32Array[][],
		_parameters: Record<string, Float32Array>,
	): boolean {
		const chData = outputs[0];
		const chDataLen = chData[0].length; // for performance
		if (!chDataLen || !this.playSpeed || !this.func) {
			return true;
		}

		let time = this.sampleRatio * this.audioSample;
		let sample = this.sample;
		const drawBuffer: DrawSample[] = [];
		for (let i = 0; i < chDataLen; i++) {
			time += this.sampleRatio;
			const flooredTime = Math.floor(time / this.sampleRateDivisor) * this.sampleRateDivisor;
			if (this.lastFlooredTime !== flooredTime) {
				const roundSample =
					Math.floor(sample / this.sampleRateDivisor) * this.sampleRateDivisor;
				let funcOut: unknown;
				try {
					if (this.songData.mode === "Funcbeat") {
						funcOut = this.func(
							roundSample / this.songData.sampleRate,
							this.songData.sampleRate / this.sampleRateDivisor,
						);
					} else {
						funcOut = this.func(roundSample);
					}
				} catch (err) {
					if (!this.sentError) {
						this.sentError = "runtime";
						this.port.postMessage({
							errorMessage: {
								err: getErrorMessage(err, roundSample),
								type: "runtime",
							},
						});
					}
					funcOut = NaN;
				}

				let stereo: [unknown, unknown];
				if (Array.isArray(funcOut) && !proxies.has(funcOut)) {
					stereo = [funcOut[0], funcOut[1]];
				} else {
					stereo = [funcOut, funcOut];
				}

				const numOut: [number, number] = stereo.map(v => {
					try {
						// WeakSet.has() works correctly with any input, not just valid WeakKeys
						// @ts-expect-error
						if (proxies.has(v)) {
							return NaN;
						}
						// this does not cast BigInt, which matches the behaviour of
						// placing it directly into a typed array
						// @ts-expect-error
						return +v;
					} catch (err) {
						return NaN;
					}
				}) as [number, number];

				let changedSample = false;
				numOut.forEach((v, c) => {
					if (!Object.is(v, this.lastFuncValue[c])) {
						changedSample = true;
						if (isNaN(v)) {
							this.lastByteValue[c] = NaN;
						} else {
							this.calcByteValue(v, c);
						}
					}
					this.lastFuncValue[c] = v;
				});
				if (changedSample) {
					drawBuffer.push({
						t: roundSample,
						// array needs to be copied
						value: [this.lastByteValue[0], this.lastByteValue[1]],
					});
				}

				sample += flooredTime - this.lastFlooredTime;
				this.lastFlooredTime = flooredTime;
			}
			chData[0][i] = this.lastValue[0];
			chData[1][i] = this.lastValue[1];
		}
		this.audioSample += chDataLen;

		const message = {};
		if (sample !== this.sample) {
			message.sample = sample;
		}
		if (drawBuffer.length) {
			message.drawBuffer = drawBuffer;
		}
		this.port.postMessage(message);

		this.sample = sample;
		return true;
	}
}

registerProcessor("bytebeatProcessor", BytebeatProcessor);
