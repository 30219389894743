// NOTE error call stack depends on browser and contains implementation details
// maybe proxy error to remove own code from callstack?

export function deleteGlobals() {
	// delete all single letter variables
	// a bytebeat might make some variables not enumerable, so this catches the important ones
	for (let i = 0; i < 26; i++) {
		// @ts-expect-error
		delete globalThis[String.fromCharCode(65 + i)], globalThis[String.fromCharCode(97 + i)];
	}
	// delete enumerable variables
	// @ts-expect-error
	Object.keys(globalThis).forEach((k: string & keyof typeof globalThis) => {
		// TODO: get rid of sampleRate
		if (k !== "sampleRate") {
			delete globalThis[k];
		}
	});
}

/** make all existing global properties non-writable, and freeze objects. */
export function freezeExistingGlobals() {
	// @ts-expect-error
	Object.getOwnPropertyNames(globalThis).forEach((k: string & keyof typeof globalThis) => {
		if (k !== "sampleRate") {
			const t = typeof globalThis[k];
			if ((t === "object" || t === "function") && k !== "globalThis") {
				if (t === "function" && Object.hasOwnProperty.call(globalThis[k], "prototype")) {
					// @ts-expect-error
					Object.freeze(globalThis[k].prototype);
				}
				Object.freeze(globalThis[k]);
			}
			Object.defineProperty(globalThis, k, {
				writable: false,
				configurable: false,
			});
		}
	});
}
