// ideally in the future this will do far better optimizations to code by doing real code parsing
// currently this detects one not very common but important case

/**
 * detect eval(unescape(escape(<const string>).replace(/u(..)/g, "$1%")))
 * and replace it with the generated code.
 */
function constEval(expression: string): string {
	let hadStart = false;
	const withoutStart = expression.replace(
		/^eval\s*\(\s*unescape\s*\(\s*escape/,
		() => {hadStart = true; return ""},
	);
	if (hadStart) {
		let hadEnd = false;
		const withoutEnd = withoutStart.replace(
			/\.replace\(\/u\(\.\.\)\/g,([`"'])\$1%\1\)\)\)$/,
			() => {hadEnd = true; return ""},
		);
		if (hadEnd) {
			let hadParens = false;
			const content = withoutEnd.replace(
				/^\s*\((?<content>.*)\)\s*$/s,
				(_, content) => {hadParens = true; return content},
			);
			const part4 = content
				.trim()
				.match(hadParens ? /^(?<quote>[`"'])(?<content>.*)\1$/s : /^`(?<content>.*)`$/s);
			if (part4) {
				const groups = part4.groups!; // the regex is a constant
				const stringContent = groups["content"];
				const stringQuote = groups["quote"] ?? "`";
				if (
					!stringContent.includes(stringQuote) &&
					!stringContent.includes("\\") && // TODO: improve escape handling
					(stringQuote !== "`" | !stringContent.includes("${"))
				) {
					return unescape(escape(stringContent).replace(/u(..)/g, "$1%"));
				}
			}
		}
	}
	return expression;
}

export function transformCode(script: string, isExpression: boolean): string {
	script = script.trim();
	if (isExpression) {
		script = constEval(script);
	}
	return script;
}
